.table-curved {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 12px;
    border-collapse: separate;
}
.table-curved {
    border: solid black 1px;
    border-radius: 20px;
}
.table-curved td, .table-curved th {
    border-left: 1px solid black;
    border-top: 1px solid black;
    padding: 7px;
}
.table-curved tr > *:first-child {
    border-left: 0px;
}
.table-curved tr:first-child > * {
    border-top: 0px;
}

.div1{
    border: 1px solid black;
    width: 95%;
    border-radius: 20px;
}
.clvdipp{font-family: "Helvetica" ;font-size: 6px;}
.tablapie {font-family: "Helvetica" ;font-size: 7px;   margin: 45px; width: 480px; border-collapse: separate; border-spacing: 4px; empty-cells: hide; }
.celdapie { border: 1px solid black; width: 175px; height: 70px;}

.titulo1 {font-family: "Helvetica" ;font-size: 11px; text-align: right; }
.celsola1{ border-left-color: black; border-bottom-color: black;  border-right-color: black; border-top-color: black;}
.celizqH1{ text-align: right; font-size: 10px; border-left-color: white; border-bottom-color: white; border-right-color: black; border-top-color: white }