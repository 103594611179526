.div1 {
    border: 1px solid black;
    width: 95%;
    border-radius: 25px;
}
.table1 {
    border-collapse: collapse;
    border: red;
    width: 100%;
    height: 150px;
}
.tdnorm {border: 1px solid black;  padding: 10px;  }
.tdtl { border: transparent;  padding: 10px;  }
.tdtr { border: transparent;  padding: 10px;  }
.tdbr { border: transparent;  padding: 10px;  }
.tdbl { border: transparent;  padding: 10px;  }