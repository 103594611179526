.main_menu { 
    padding-top: 70px; 
} 

header, footer, .content {
    margin-left: 5%;
    margin-right: 5%;
}

.flash-message  {
    opacity: 0.7;
    min-width: 150px;
    float: right;
}

.mt-1 {
    margin-top: .25rem;
}

.mt-2 {
    margin-top: .5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: .25rem;
}

.mb-2 {
    margin-bottom: .5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.ms-1 {
    margin-left: .25rem;
}

.ms-2 {
    margin-left: .5rem;
}

.ms-3 {
    margin-left: 1rem;
}

.ms-4 {
    margin-left: 1.5rem;
}

.ms-5 {
    margin-left: 3rem;
}

.me-1 {
    margin-right: .25rem;
}

.me-2 {
    margin-right: .5rem;
}

.me-3 {
    margin-right: 1rem;
}

.me-4 {
    margin-right: 1.5rem;
}

.me-5 {
    margin-right: 3rem;
}

.mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
}

.mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
}

.mx-3 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mx-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.mx-5 {
    margin-left: 3rem;
    margin-right: 3rem;
}