/*
body { padding-top: 70px; }
*/

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a:link {
    color: #FFFFFF;
}

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a:hover {
    color: darkgray;
}

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a:visited {
    color: #FFFFFF;
}